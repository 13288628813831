import React from "react";
import styled from "styled-components";
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { colors } from "../colors/colors";

const SocialsContainer = styled.div`
  display: ${(props) => (props.open ? "grid" : "none")};
  grid-template-columns: repeat(5, 1fr);
`;

const SocialIcon = styled.div`
  font-size: 1.1rem;
  display: inline;
  transition: 0.5s ease;
  margin: 0 7px;
  color: ${colors.red};

  &:hover {
    color: ${colors.red};
  }
`;

const Socials = (props) => (
  <SocialsContainer open={props.open}>
    <SocialIcon>
      <a
        style={{ color: "inherit" }}
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.facebook.com/yuplifett/"
      >
        <FaFacebookF />
      </a>
    </SocialIcon>
    <SocialIcon>
      <a
        style={{ color: "inherit" }}
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.twitter.com/yuplifett"
      >
        <FaTwitter />
      </a>
    </SocialIcon>
    <SocialIcon>
      <a
        style={{ color: "inherit" }}
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.instagram.com/yuplife/"
      >
        <FaInstagram />
      </a>
    </SocialIcon>
    <SocialIcon>
      <a
        style={{ color: "inherit" }}
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.youtube.com/channel/UCPi4GTCuKQjrQzqbciKglQg"
      >
        <FaYoutube />
      </a>
    </SocialIcon>
    <SocialIcon>
      <a
        style={{ color: "inherit" }}
        rel="noopener noreferrer"
        target="_blank"
        href="https://www.tiktok.com/@yuplifett"
      >
        <FaTiktok />
      </a>
    </SocialIcon>
  </SocialsContainer>
);

export default Socials;
