import React, { Component } from "react";
import styled from "styled-components";

import logo from "../images/logo.png";
import CreatorTag from "./creator-tag";

import {
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaFacebookF,
  FaTiktok,
} from "react-icons/fa";
import { colors } from "../colors/colors";

const FooterContainer = styled.div`
  background-color: #200128;
  margin: 30px 0 0 0;
  width: 100%;
`;
const FooterGroup = styled.div`
  padding: 30px 20px;
  max-width: 1000px;
  margin: 0 auto;
  display: block;
`;
const LogoFooterContainer = styled.div`
  margin: 0 auto;
`;

const FooterLogo = styled.img`
  width: 50px;
  margin: 0;
`;
const AddressContainer = styled.div`
  margin: 0;
`;
const Address = styled.p`
  font-family: "Poppins", sans-serif;
  color: #fff;
  line-height: 170%;
  font-size: 16px;
  margin: 60px 0 60px 0;
`;
const HoursContainer = styled.div``;
const HoursText = styled.h5`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  margin: 10px 0;
`;
const SocialsContainer = styled.div`
  margin: 30px 0 20px 0;
  color: ${colors.red};
  font-size: 1.5rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  max-width: 180px;
`;
class Footer extends Component {
  state = {};
  render() {
    return (
      <FooterContainer>
        <FooterGroup>
          <LogoFooterContainer>
            <FooterLogo src={logo} alt="YUP Life Logo" />
          </LogoFooterContainer>
          <AddressContainer>
            <Address>
              Corner of Robert and Fitt Street,
              <br />
              Woodbrook,
              <br />
              Port of Spain,
              <br />
              Trinidad
            </Address>
          </AddressContainer>
          <HoursContainer>
            <HoursText>yupentertainment@hotmail.com</HoursText>
          </HoursContainer>
          <SocialsContainer>
            <a
              style={{ color: "inherit" }}
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.facebook.com/yuplifett/"
            >
              <FaFacebookF />
            </a>
            <a
              style={{ color: "inherit" }}
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.instagram.com/yuplife/"
            >
              <FaInstagram />
            </a>
            <a
              style={{ color: "inherit" }}
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.youtube.com/channel/UCPi4GTCuKQjrQzqbciKglQg"
            >
              <FaYoutube />
            </a>
            <a
              style={{ color: "inherit" }}
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.twitter.com/yuplifett"
            >
              <FaTwitter />
            </a>
            <a
              style={{ color: "inherit" }}
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.tiktok.com/@yuplifett"
            >
              <FaTiktok />
            </a>
          </SocialsContainer>
        </FooterGroup>
        <CreatorTag />
      </FooterContainer>
    );
  }
}

export default Footer;
