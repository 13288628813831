import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import HamburgerMenu from "react-hamburger-menu";
import Menu from "./menu";
import { colors } from "../colors/colors";

const HeaderContainer = styled.header`
  width: 100%;
  padding: 3% 5%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s ease;
  background-color: white;

  @media (min-width: 851px) {
    display: none;
  }
`;
const LogoContainer = styled.div`
  margin-left: 5px;
  position: relative;
  /* justify-self: center; */
  display: inline-block;
  z-index: 2;
  display: flex;
  float: left;
  height: 100%;
  width: 40px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: all 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);

  img {
    margin: 0;
  }
`;
const Right = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 100;
`;

class NavMobile extends React.Component {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  handleScroll = (event) => {
    const scrollTop = window.pageYOffset;
    const { stuck } = this.state;
    // console.log(scrollTop)

    if (scrollTop > 65) {
      if (stuck) {
        //Do NOthing, return
        return;
      }
      this.setState({ isScrolled: true, stuck: true });
      // this.props.handleSticky(true)
    } else {
      this.setState({ isScrolled: false, stuck: false });
      // this.props.handleSticky(false)
    }
  };

  render() {
    return (
      <HeaderContainer
        hideNav={this.props.hideNav}
        isScrolled={this.state.isScrolled}
      >
        <LogoContainer>
          <Link to="/">
            <img alt="yuplogo" src={require("../images/logo.png")} />
          </Link>
        </LogoContainer>
        <Right>
          <HamburgerMenu
            isOpen={this.state.isOpen}
            menuClicked={() => this.setState({ isOpen: !this.state.isOpen })}
            width={33}
            height={17}
            strokeWidth={2}
            rotate={0}
            color={colors.red}
            borderRadius={0}
            animationDuration={0.5}
          />
          <Menu isOpen={this.state.isOpen} />
        </Right>
      </HeaderContainer>
    );
  }
}

export default NavMobile;
