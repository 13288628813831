import React from "react";
import styled from "styled-components";

const CreatorTagContainer = styled.div`
  padding: 20px;
  background-color: white;
  width: 100%;

  border-bottom: 2px solid white;
`;

const Copy = styled.h3`
  font-size: 15px;
  padding: 0;
  color: rgb(230, 41, 41);
  font-family: "bebasbold";
  margin: 0;
  text-decoration: underline;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
`;

class CreatorTag extends React.Component {
  render() {
    return (
      <CreatorTagContainer>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://andelhusbands.xyz/"
        >
          <Copy>Hand Coded by Andel Husbands</Copy>
        </a>
      </CreatorTagContainer>
    );
  }
}

export default CreatorTag;
