import React from "react";
import styled, { keyframes } from "styled-components";

import { Link, navigate } from "gatsby";

import "./navbar-new.css";
import { colors } from "../colors/colors";

class Navbar2 extends React.Component {
  state = {
    stuck: false,
    isScrolled: false,
  };

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  handleScroll = (event) => {
    const scrollTop = window.pageYOffset;
    const { stuck } = this.state;

    if (scrollTop > 65) {
      if (stuck) {
        //Do NOthing, return
        return;
      }
      this.setState({ isScrolled: true, stuck: true });
    } else {
      this.setState({ isScrolled: false, stuck: false });
    }
  };
  render() {
    const { isScrolled } = this.state;
    return (
      <Container isScrolled={isScrolled}>
        <InnerContainer>
          <LogoContainer onClick={() => navigate("/")}>
            <Logo src={require("../images/logo.png")} />
          </LogoContainer>
          <Nav isScrolled={isScrolled}>
            {links.map((link, index) => (
              <NavItem key={index} isScrolled={isScrolled}>
                {link.external ? (
                  <a
                    style={{
                      textDecoration: "inherit",
                      color: "inherit",
                    }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={link.link}
                  >
                    {link.title}
                  </a>
                ) : (
                  <Link
                    partiallyActive={link.title !== "Home"}
                    activeStyle={{
                      color: colors.red,
                      fontWeight: "600",
                    }}
                    style={{
                      textDecoration: "inherit",
                      color: "inherit",
                    }}
                    to={`${link.link}`}
                  >
                    {link.title}
                  </Link>
                )}
              </NavItem>
            ))}
          </Nav>
        </InnerContainer>
      </Container>
    );
  }
}
export default Navbar2;

const links = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Event & Tickets",
    link: "/events",
  },
  {
    title: "Shop",
    link: "/shop",
  },
  // {
  //   title: "Tickets",
  //   link: "/tickets",
  // },
  // {
  //   title: "Activate Your Wristband",
  //   link: 'https://ticketbanton.com/activations/event/41420191216102941',
  //   external: true
  // },
  {
    title: "Media",
    link: "/media",
  },
  {
    title: "YUP Cares",
    link: "/yup-cares",
  },
  {
    title: "YUP Adventures",
    link: "/yup-adventures",
  },
  {
    title: "Join",
    link: "/join",
  },
];

const Container = styled.div`
  width: 100%;
  padding: 10px 20px;
  background-color: ${(props) =>
    props.isScrolled ? "rgba(255,255,255,0.95)" : "none"};
  /* background: black; */
  box-shadow: ${(props) => props.isScrolled && "0 2px 4px rgba(0, 0, 0, 0.1)"};
  z-index: 100;
  position: sticky;
  top: 0;
  transition: 0.8s ease-out;

  @media (max-width: 850px) {
    display: none;
  }
`;
const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1080px;
  margin: 0 auto;
`;
const LogoContainer = styled.div`
  flex: 1;
  max-width: 50px;
  cursor: pointer;
`;
const Logo = styled.img`
  transition: 0.8s ease-out;
  margin: 0;
`;
const Nav = styled.ul`
  margin: 0;
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: flex-end;
  align-items: center;
  transition: 0.8s ease-out;
  flex: 2;
  color: ${(props) => (props.isScrolled ? "#434343" : colors.copy)};
`;
const Expand = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 60%;
  }
`;
const NavItem = styled.li`
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  margin: 0 10px;
  white-space: nowrap;
  /* text-shadow: ${(props) =>
    !props.isScrolled && "0 2px 4px rgba(0, 0, 0, 0.15)"}; */
  text-transform: uppercase;
  transition: 0.2s ease;

  :hover {
    /* color: white; */
    /* font-weight: 600; */

    ::after {
      content: "";
      background-color: ${colors.red};
      width: 0%;
      animation: ${Expand} 0.3s ease-in-out forwards;
      display: block;
      margin: 0 auto;
      height: 3px;
    }
  }
`;
