import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import "./layout.css";
import Footer from "./footer";
import Navbar2 from "./navbar-new";
import NavMobile from "./nav-mobile";

const Layout = ({ noNav, noFooter, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        {!noNav && <Navbar2 />}
        {!noNav && <NavMobile />}
        <main style={{ paddingTop: 20 }}>{children}</main>
        {!noNav && <Footer />}
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
