import React from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import { Colors, colors } from "../colors/colors";
import Socials from "./socials";

class Menu extends React.PureComponent {
  state = {
    menuOpen: false,
  };

  closeMenu = (event) => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      this.setState({ menuOpen: false });
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.closeMenu, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.closeMenu, false);
  }

  render() {
    return (
      <Wrapper
        onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}
        open={this.props.isOpen}
      >
        <MenuList open={this.props.isOpen}>
          <img
            alt="yuplogo"
            onClick={() => navigate("/")}
            style={{ height: "45px", marginBottom: "40px" }}
            src={require("../images/logo.png")}
          />
          {links.map((link, index) => (
            <Item key={index}>
              {link.external ? (
                <a rel="noopener noreferrer" href={link.link} target="_blank">
                  {link.title}
                </a>
              ) : (
                <Link
                  to={`${link.link}`}
                  partiallyActive={link.title !== "Home"}
                  activeStyle={{
                    color: colors.red,
                  }}
                >
                  {link.title}
                </Link>
              )}
            </Item>
          ))}
        </MenuList>
        <Socials gold open={this.props.isOpen} />
      </Wrapper>
    );
  }
}
export default Menu;

const links = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Events & Tickets",
    link: "/events",
  },
  {
    title: "Shop",
    link: "/shop",
  },
  //   {
  //     title: "Tickets",
  //     link: "/tickets",
  //   },
  // {
  //   title: "Activate Your Wristband",
  //   link: 'https://ticketbanton.com/activations/event/41420191216102941',
  //   external: true
  // },
  {
    title: "Media",
    link: "/media",
  },
  {
    title: "YUP Cares",
    link: "/yup-cares",
  },
  {
    title: "YUP Adventures",
    link: "/yup-adventures",
  },
  {
    title: "Join",
    link: "/join",
  },
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: ${(props) => (props.open ? "85vw" : 0)};
  /* border-left: 1px solid ${Colors.gold}; */
  opacity: 0.98;
  box-shadow: 0px 0 5px rgba(0, 0, 0, 0.15);
  transition: width 0.3s;
  z-index: -1;
`;
const MenuList = styled.ul`
  margin: 50px 0 0 0;
  list-style: none;
  display: ${(props) => (props.open ? "block" : "none")};
  transition: opacity 0.1s;
  transition-delay: 0.1s;
  font-family: "bebasbold";
  color: black;
  text-align: center;
  opacity: 0.9;
`;
const Item = styled.li`
  padding-left: 0;
  margin-bottom: ${(props) => (props.space ? "2rem" : "0.725rem")};
  color: black;

  a {
    font-size: 22px;
    line-height: 1;
    text-decoration: none;
    color: ${colors.copy};

    @media only screen and (max-width: 768px) {
      /* font-size: 4rem; */
      /* line-height: 1.5; */
    }
    @media only screen and (max-width: 480px) {
      /* font-size: 3rem; */
      /* line-height: 1.5; */
    }
  }
`;
